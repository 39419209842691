<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                    <div class="card bg-light d-flex flex-fill">
                        <div class="card-header text-muted border-bottom-0">
                        Daftar Soal Latihan
                        </div>
                        <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-12">
                            <h2 class="lead"><b>{{ ujian.name }}</b></h2>
                            </div>
                        </div>
                        </div>
                        <div class="card-footer">
                        <div class="text-right">
                    		<router-link :to='tipe_ujian == "ujian" ? "/ujian-mapel" : "/soal-mapel"' class="btn btn-sm btn-secondary">
                            <i class="fas fa-arrow-left"></i> Ke Halaman Pilih Jenis Soal Latihan
                    		</router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
			<table class="table table-hover" ref="tblujian">
			<thead>
				<tr>
                    <th>LATIHAN</th>
                    <th>JUMLAH SOAL</th>
                    <th>TINDAKAN</th>
				</tr>
			</thead>
			<tbody @click="handleClick"></tbody>
			</table>
        </div>
    </section>
</template>
<script>
	import { createTable, authFetch } from "@/libs/hxcore";
    export default {
    name: 'UjianMapelList',
    data: () => ({
        ujian: {},
        roles: "",
        tipe_ujian: '',
    }),
    created: function () {
        let id = this.$route.params.id;
        this.loadMapel(id);

		this.roles = this.$route.meta.roles;
		this.tipe_ujian = this.$route.params.type;
    	console.log("load initial data", this.$route);
    },
    methods: {
        loadMapel(id){
			authFetch("/siswa/ujian/list/" + id).then((res) => {
				res.json().then((json) => {
				this.ujian = json;
				console.log(this.ujian);
				});
			});
        },
		handleClick(e) {
            if (e.target.matches(".btn-success")) {
                if (e.target.dataset.action == "view") {
                    this.$router.push("/ujian-finish/" + e.target.dataset.id + "/" + e.target.dataset.mapel_id + "/" + e.target.dataset.type)
                }
            }
		},
    },
    mounted(){
	const e = this.$refs;
	let self = this;

	this.table = createTable(e.tblujian, {
		title: "",
		language: {
			zeroRecords: "Tidak ada soal latihan."
		},
		selectedRowClass: '',
		roles: ["read"],
		ajax: "/tentor/ujian/ujian_list",
		searching: true,
		paramData: function(d) {
			d.mapel_id = self.$route.params.id;
			d.type = self.$route.params.type;
		},
		columns: [
			{ data: "ujian_title" },
			{ data: "total_soal",
			render: function (data, type, row, meta) {
				return `<h5>${data} Soal</h5>`;
			}, },
			{ data: "action",
			sortable: false,
			render: function (data, type, row, meta) {
                return `<button type="button" class="btn btn-sm btn-success" data-action="view" data-id="` + row.ujian_code + `" data-mapel_id="` + self.$route.params.id + `" data-type="` + self.$route.params.type + `"><i class="fas fa-eye"></i> Pembahasan </button>`;
			}, },
		],
		filter: false
	});
    },
}
</script>
<style type="text/css" scoped>
</style>